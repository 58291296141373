import { mapGetters } from "vuex";
import { isObject, isEmptyObjectCheck } from "@/helpers/helper";
export default {
  computed: {
    ...mapGetters(["getUser","getUserDetails"]),
    isJobSupplier() {
      let admin_roles = this.getUserDetails?.admin_roles || [];
      let recruiter_roles = this.getUserDetails?.recruiter_roles || [];
      if (admin_roles?.length) {
        return _(admin_roles).some((role) => role.id == 1) || false;
      } else if (recruiter_roles?.length) {
        return _(recruiter_roles).some((role) => role.id == 8) || false;
      }
      return false;
    },
  },
};
